import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FilterState {
  filter: string;
  authFilter: string;
  hostFilter: string;
  vpsFilter: string;
  versionFilter: string;
  timezoneFilter: string;
  limitFilter: number;
  typificationFilter: string;
  chatsFilter: string;
  activeFilterType: 'host' | 'vps' | 'version';
}

const initialState: FilterState = {
  filter: '',
  authFilter: '',
  hostFilter: '',
  vpsFilter: '',
  versionFilter: '',
  timezoneFilter: '',
  limitFilter: 5000,
  typificationFilter: '',
  chatsFilter: '',
  activeFilterType: 'host',
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<string>) => {
      state.filter = action.payload;
    },
    setAuthFilter: (state, action: PayloadAction<string>) => {
      state.authFilter = action.payload;
    },
    setHostFilter: (state, action: PayloadAction<string>) => {
      state.hostFilter = action.payload;
    },
    setVpsFilter: (state, action: PayloadAction<string>) => {
      state.vpsFilter = action.payload;
    },
    setVersionFilter: (state, action: PayloadAction<string>) => {
      state.versionFilter = action.payload;
    },
    setTimezoneFilter: (state, action: PayloadAction<string>) => {
      state.timezoneFilter = action.payload;
    },
    setLimitFilter: (state, action: PayloadAction<number>) => {
      state.limitFilter = action.payload;
    },
    setTypificationFilter: (state, action: PayloadAction<string>) => {
      state.typificationFilter = action.payload;
    },
    setChatsFilter: (state, action: PayloadAction<string>) => {
      state.chatsFilter = action.payload;
    },
    setActiveFilterType: (state, action: PayloadAction<'host' | 'vps' | 'version'>) => {
      state.activeFilterType = action.payload;
    },
  },
});

export const {
  setFilter,
  setAuthFilter,
  setHostFilter,
  setVpsFilter,
  setVersionFilter,
  setTimezoneFilter,
  setLimitFilter,
  setTypificationFilter,
  setChatsFilter,
  setActiveFilterType,
} = filterSlice.actions;

export default filterSlice.reducer;
