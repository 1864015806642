import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SelectedAccountState {
  selectedAccount: string | null | undefined;
}

const initialState: SelectedAccountState = {
  selectedAccount: null,
};

export const selectedAccountSlice = createSlice({
  name: 'selectedAccount',
  initialState,
  reducers: {
    setSelectedAccount: (state, action: PayloadAction<string | null | undefined>) => {
      state.selectedAccount = action.payload;
    },
  },
});

export const { setSelectedAccount } = selectedAccountSlice.actions;
export default selectedAccountSlice.reducer;
