import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthUser {
  id: string;
  fullName: string | null | undefined;
  email: string | null | undefined;
  imageUrl: string | null | undefined;
}

interface AuthState {
  user?: AuthUser;
  isLoaded: boolean;
}

const initialState: AuthState = {
  user: undefined,
  isLoaded: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthState: (
      state,
      action: PayloadAction<{ user: AuthUser | undefined; isLoaded: boolean }>
    ) => {
      state.user = action.payload.user;
      state.isLoaded = action.payload.isLoaded;
    },
  },
});

export const { setAuthState } = authSlice.actions;
export default authSlice.reducer;
