import { Badge, ConfigProvider } from 'antd';
import { ConnectionSessionWAList } from 'features/waAccounts/__generated__/ConnectionSessionWAListQuery';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { setVersionFilter } from 'store/slices/filterSlice';
import { RootState } from 'store/store';
import { themeIsDark } from 'utils/appTheme';

interface Props {
  accs: ConnectionSessionWAList | undefined;
  versions: Record<string, number> | undefined;
}

const VersionFilters = ({ accs, versions }: Props) => {
  const dispatch = useDispatch();
  const { versionFilter, authFilter } = useSelector((state: RootState) => state.filter);

  const handleVersionFilterChange = (value: string) => {
    dispatch(setVersionFilter(versionFilter === value ? '' : value));
  };

  return (
    <motion.div
      key="version-filters"
      animate={{ opacity: [0, 1], gap: [0, '8px'] }}
      transition={{ duration: 0.3, delay: 0.2 }}
      className="flex flex-row items-center"
    >
      {Object.entries(versions || {})
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(([key], index) => (
          <ConfigProvider
            key={index}
            theme={{
              token: {
                colorBorderBg: 'transparent',
                fontSize: 13,
                colorBgBase: themeIsDark ? 'white' : 'black',
              },
              components: {
                Badge: {
                  indicatorZIndex: 20,
                },
              },
            }}
          >
            <Badge
              color={themeIsDark ? '#505b65' : '#eaf5ff'}
              count={
                accs?.connectionSessionWAList?.filter((acc) => {
                  const status = acc?.waClient?.status?.toString()?.toLowerCase() || '';
                  const filter = authFilter?.toLowerCase() || '';
                  const version = acc?.versionCode || '';
                  return status.includes(filter) && version === key;
                }).length
              }
              overflowCount={999}
            >
              <button
                key={key}
                onClick={() => handleVersionFilterChange(key)}
                className={`group flex select-none items-center justify-center rounded-lg duration-150 dark:text-white ${versionFilter === key ? 'bg-green-600' : 'bg-surfaceDarker hover:bg-green-200 dark:bg-surfaceDark dark:hover:bg-green-600/40'} gap-2 px-3 py-1`}
              >
                <p
                  className={`text-sm font-medium ${versionFilter === key ? 'text-white' : 'dark:text-white'}`}
                >
                  {key}
                </p>
              </button>
            </Badge>
          </ConfigProvider>
        ))}
    </motion.div>
  );
};

export default VersionFilters;
