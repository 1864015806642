import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SelectedClientState {
  selectedClient: string | null;
  singleClient: boolean;
}

const initialState: SelectedClientState = {
  selectedClient: null,
  singleClient: false,
};

const selectedClientSlice = createSlice({
  name: 'selectedClient',
  initialState,
  reducers: {
    setSelectedClient: (state, action: PayloadAction<string | null>) => {
      state.selectedClient = action.payload;
    },
    setSingleClient: (state, action: PayloadAction<boolean>) => {
      state.singleClient = action.payload;
    },
  },
});

export const { setSelectedClient, setSingleClient } = selectedClientSlice.actions;
export default selectedClientSlice.reducer;
