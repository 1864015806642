import { useDispatch } from 'react-redux';
import { setHostFilter, setVpsFilter, setVersionFilter } from 'store/slices/filterSlice';

interface Props {
  filter: 'host' | 'vps' | 'version';
  setFilter: (filter: 'host' | 'vps' | 'version') => void;
}

const FilterTumbler = ({ filter, setFilter }: Props) => {
  const dispatch = useDispatch();

  const handleHostFilter = () => {
    setFilter('host');
    dispatch(setVpsFilter(''));
    dispatch(setVersionFilter(''));
  };

  const handleVpsFilter = () => {
    setFilter('vps');
    dispatch(setHostFilter(''));
    dispatch(setVersionFilter(''));
  };

  const handleVersionFilter = () => {
    setFilter('version');
    dispatch(setHostFilter(''));
    dispatch(setVpsFilter(''));
  };

  return (
    <div className="relative grid h-10 grid-flow-col items-center gap-4 rounded-full bg-surfaceDarker px-3 shadow-sm dark:bg-surfaceDark">
      <p
        className={`${
          filter === 'host' ? '' : 'opacity-40'
        } relative z-20 cursor-pointer select-none text-sm transition-all duration-200 dark:text-white`}
        onClick={handleHostFilter}
      >
        HOST
      </p>
      <p
        className={`${
          filter === 'vps' ? '' : 'opacity-40'
        } relative z-20 cursor-pointer select-none justify-self-center text-sm transition-all duration-200 dark:text-white`}
        onClick={handleVpsFilter}
      >
        VPS
      </p>
      <p
        className={`${
          filter === 'version' ? '' : 'opacity-40'
        } relative z-20 cursor-pointer select-none justify-self-end text-sm transition-all duration-200 dark:text-white`}
        onClick={handleVersionFilter}
      >
        VERSION
      </p>
      <div
        className={`absolute z-10 h-[80%] rounded-full bg-activeLinkLight transition-all duration-300 ease-in-out dark:bg-activeLinkDark ${
          filter === 'host'
            ? 'left-1 w-[30%]'
            : filter === 'vps'
              ? 'left-14 w-[24%]'
              : 'left-[6rem] w-[43%]'
        }`}
      />
    </div>
  );
};

export default FilterTumbler;
