import { Badge, ConfigProvider } from 'antd';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from 'store/store';
import { setFilter, setAuthFilter, setHostFilter } from 'store/slices/filterSlice';
import { themeIsDark } from 'utils/appTheme';

interface AccountFilterProps {
  linkFilter: string;
  className?: string;
  allAccounts?: number;
  activeAccounts?: number;
}

export const AccountFilter = memo((props: AccountFilterProps) => {
  const { linkFilter, className, allAccounts, activeAccounts } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { filter, authFilter, hostFilter } = useSelector((state: RootState) => state.filter);

  const hostFilters = {
    PC_01: 'PC01',
    PC_02: 'PC02',
    PC_03: 'PC03',
    PC_04: 'PC04',
    PC_05: 'PC05',
    PC_06: 'PC06',
    PC_07: 'PC07',
    PC_08: 'PC08',
    Phones: 'Phone',
  };

  const location = useLocation();
  const isAuthorizePage = location.pathname.includes('authorize');
  const isWaChatsPage = location.pathname.includes('wachats');
  const isActive =
    (!isAuthorizePage && filter === linkFilter) ||
    (isAuthorizePage && authFilter === linkFilter) ||
    hostFilter === linkFilter.replace('_', '') ||
    hostFilter === linkFilter.replace('Phones', 'Phone');

  const handleFilterClick = (newFilter: string) => {
    if (isAuthorizePage) {
      if (newFilter in hostFilters) {
        dispatch(
          setHostFilter(
            hostFilter === linkFilter.replace('_', '') ||
              hostFilter === linkFilter.replace('Phones', 'Phone')
              ? ''
              : hostFilters[newFilter as keyof typeof hostFilters]
          )
        );
      } else {
        dispatch(setAuthFilter(authFilter === linkFilter ? '' : newFilter));
      }
    } else {
      if (newFilter in hostFilters) {
        dispatch(
          setHostFilter(
            hostFilter === linkFilter.replace('_', '') ||
              hostFilter === linkFilter.replace('Phones', 'Phone')
              ? ''
              : hostFilters[newFilter as keyof typeof hostFilters]
          )
        );
      } else {
        dispatch(setFilter(filter === linkFilter ? '' : newFilter));
        if (isWaChatsPage) {
          navigate('/wachats');
        }
      }
    }
  };

  return (
    <div className={`${className}`}>
      <ConfigProvider
        theme={{
          components: {
            Badge: {
              indicatorZIndex: 10,
              textFontWeight: '500',
            },
          },
          token: {
            colorBorderBg: 'transparent',
          },
        }}
      >
        <Badge
          count={(activeAccounts || allAccounts) && activeAccounts + '/' + allAccounts}
          size="small"
          className="w-full select-none"
          style={{ color: '#fefefe' }}
          color={`${themeIsDark ? '#505b65' : '#6f6f6f'}`}
          overflowCount={400}
          offset={[-9, 0]}
        >
          <button
            onClick={() => handleFilterClick(linkFilter)}
            className={`${className} relative flex w-full select-none justify-center whitespace-nowrap rounded-2xl px-2 py-[9px] font-semibold duration-200 dark:text-white ${
              isActive ? 'bg-green-600' : 'inactiveLink'
            }`}
          >
            <p className={`select-none ${isActive ? 'text-white' : 'dark:text-white'}`}>
              {linkFilter}
            </p>
          </button>
        </Badge>
      </ConfigProvider>
    </div>
  );
});
