import { AccountFiltersList } from 'features/waAccounts/accountFilters';
import TypificationFilters from 'features/waTypification/typification';
import { motion } from 'framer-motion';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Plus } from 'shared/assets/icons/broadcast/icons';
import { Accounts, Broadcast, Messanger, Typification, VPS } from 'shared/assets/icons/icons';
import NavLink from 'shared/components/navLink';
import { RootState } from 'store/store';

const SideMenu = () => {
  const { userRole, userName } = useSelector((state: RootState) => state.user);
  const pathname = useLocation().pathname;
  const isWaChatsPage = pathname.startsWith('/wachats/') || pathname === '/wachats';
  const isBroadcastPage = pathname.startsWith('/broadcast/') || pathname === '/broadcast';
  const isTypificationPage = pathname.startsWith('/typification/') || pathname === '/typification';
  const isBroadcastCreatePage =
    pathname.startsWith('/broadcast/create') || pathname === '/broadcast/create';

  const showUserMenu = userName && !userName.includes('Сафронова');
  const isAdmin = userRole.includes('admin');

  return (
    <div className="flex flex-col gap-y-2">
      {showUserMenu && (
        <>
          <NavLink href="/wachats" className="p-2">
            <div className="relative flex w-full flex-row items-center gap-x-3">
              <Messanger className="size-8" />
              <p className="select-none">Мессенджер</p>
            </div>
          </NavLink>
          <div className="relative flex flex-col">
            <NavLink
              href="/broadcast"
              className={`relative z-30 ${isBroadcastPage && !isBroadcastCreatePage ? 'mb-8' : ''} p-2`}
            >
              <div className="flex w-full flex-row items-center gap-x-3">
                <Broadcast className="size-8" />
                <p className="select-none">Рассылка</p>
              </div>
            </NavLink>
            {isBroadcastPage && !isBroadcastCreatePage ? (
              <Link to="/broadcast/create" className="absolute top-8 w-full">
                <motion.button
                  key={'create'}
                  initial={{ scale: 0.9, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  className="mx-auto flex h-12 w-[85%] select-none items-end justify-center rounded-2xl bg-surfaceLight px-3 py-1 text-sm font-semibold duration-150 hover:bg-activeLinkLight dark:bg-surfaceLighter dark:text-white dark:hover:bg-activeLinkDark"
                >
                  <div className="flex flex-row items-center gap-2">
                    <p className="whitespace-normal py-1 xl:whitespace-nowrap dark:text-white">
                      Создать рассылку
                    </p>
                    <Plus className="hidden size-5 2xl:block" />
                  </div>
                </motion.button>
              </Link>
            ) : null}
          </div>
          {/* <NavLink href="/base-create" className="relative z-30 hidden p-2">
            <div className="flex w-full flex-row items-center gap-x-3">
              <Base className="size-8" />
              <p className="select-none leading-tight">Создание базы</p>
            </div>
          </NavLink> */}
        </>
      )}
      {isAdmin && (
        <>
          <div className="flex flex-row gap-x-2">
            <NavLink href="/nodes" className="flex w-full flex-col items-center p-2">
              <VPS className="size-8" />
              <p className="select-none">VPS</p>
            </NavLink>
            <NavLink href="/authorize" className="flex w-full flex-col items-center p-2">
              <Accounts className="size-8" />
              <p className="select-none">Аккаунты</p>
            </NavLink>
          </div>
          <NavLink href="/typification" className={`relative z-30 p-2`}>
            <div className="flex w-full flex-row items-center gap-x-3">
              <Typification className="size-8" />
              <p className="select-none leading-tight">Типизация</p>
            </div>
          </NavLink>
        </>
      )}
      {/* <NavLink href="/best-offer" className={`relative z-30 p-2`}>
        <div className="flex w-full flex-row items-center gap-x-3">
          <Proposal className="size-8" />
          <p className="select-none whitespace-nowrap leading-tight">Крутое предложение</p>
        </div>
      </NavLink> */}

      {isWaChatsPage && isAdmin && <AccountFiltersList />}
      {isTypificationPage && <TypificationFilters />}
    </div>
  );
};

export default memo(SideMenu);
