import { SignedIn, SignedOut, SignInButton, UserButton } from '@clerk/clerk-react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Logo, LogoMobile } from 'shared/assets/icons/icons';
import HostFilters from 'shared/components/filters/hostFilters';
import LimitFilters from 'shared/components/filters/limitFilters';
import TimezoneFilters from 'shared/components/filters/timezoneFilters';
import VersionFilters from 'shared/components/filters/versionFilters';
import VpsFilters from 'shared/components/filters/vpsFilters';
import FilterTumbler from 'shared/components/filterTumbler/filterTumbler';
import ThemeTumbler from 'shared/components/themeTumbler/ui/themeTumbler';
import { RootState } from 'store/store';

export const Header = () => {
  const location = useLocation();
  const isAuthorizePage = location.pathname.includes('authorize');
  const isBroadcastPage =
    location.pathname.includes('broadcast') &&
    !location.pathname.includes('create') &&
    !location.pathname.includes('edit');

  const [filter, setFilter] = useState<'host' | 'vps' | 'version'>('host');

  const { accounts, versions } = useSelector((state: RootState) => state.accounts);

  return (
    <header className="flex h-[100px] w-full flex-row items-center justify-between px-[6%] lg:px-[1%]">
      <div className="flex flex-row items-center gap-x-4">
        <Link to="/">
          <Logo className="hidden md:block" />
          <LogoMobile className="block size-12 md:hidden" />
        </Link>
        {isAuthorizePage && (
          <div className="flex flex-row items-center gap-3">
            <FilterTumbler filter={filter} setFilter={setFilter} />
            {filter === 'host' ? (
              <HostFilters accs={accounts} />
            ) : filter === 'vps' ? (
              <VpsFilters accs={accounts} />
            ) : (
              <VersionFilters accs={accounts} versions={versions || {}} />
            )}
          </div>
        )}
        {isBroadcastPage && (
          <div className="flex flex-col gap-1">
            <div className="flex flex-row items-center gap-2">
              <p className="select-none text-sm text-gray-500 dark:text-gray-400">Часовые пояса</p>
              <TimezoneFilters />
            </div>
            <div className="flex flex-row items-center gap-2">
              <p className="select-none text-sm text-gray-500 dark:text-gray-400">Лимит клиентов</p>
              <LimitFilters />
            </div>
          </div>
        )}
      </div>
      <div className="ml-4 flex flex-row items-center gap-x-4">
        <ThemeTumbler />
        <SignedOut>
          <SignInButton />
        </SignedOut>
        <SignedIn>
          <UserButton />
        </SignedIn>
      </div>
    </header>
  );
};

export default Header;
