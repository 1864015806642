import { ApolloProvider } from '@apollo/client';
import { ClerkProvider } from '@clerk/clerk-react';
import { ruRU } from '@clerk/localizations';
import { client } from 'apollo/client';
import App from 'app/app';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store/store';
import './app/styles/index.scss';

const CLERK_PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!CLERK_PUBLISHABLE_KEY) {
  throw new Error('Missing Clerk Publishable Key');
}

const container = document.querySelector('#root') as HTMLElement;
const root = createRoot(container);

root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <ClerkProvider publishableKey={CLERK_PUBLISHABLE_KEY} localization={ruRU}>
        <Provider store={store}>
          <App />
        </Provider>
      </ClerkProvider>
    </BrowserRouter>
  </ApolloProvider>
);
//
