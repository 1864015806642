import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  userRole: string;
  userOrg: string;
  userName: string;
}

const initialState: UserState = {
  userRole: '',
  userOrg: '',
  userName: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserState>) => {
      state.userRole = action.payload.userRole;
      state.userOrg = action.payload.userOrg;
      state.userName = action.payload.userName;
    },
  },
});

export const { setUserData } = userSlice.actions;
export default userSlice.reducer;
