// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import { ConnectionSessionWA } from '../../fragments/__generated__/ConnectionSessionWA.fragment';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type ConnectionSessionWASubscriptionVariables = Types.Exact<{
  host?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ConnectionSessionWASubscription = { __typename: 'Subscription', connectionSessionWASubscribe?: { __typename: 'ClientStatus', statusWbot?: string | null, percent?: number | null, notification?: string | null, needPhone?: string | null, nameVitrualMachine?: string | null, linkCode?: string | null, versionCode?: string | null, waClient?: { __typename: 'ClientStatusWaClient', _id?: any | null, name?: string | null, phone?: number | null, email?: string | null, unreadCount?: number | null, status?: Types.EnumClientStatusWaClientStatus | null, needConnectQrCode?: boolean | null, profilePicUrl?: string | null, host?: string | null, zennoPosterHost?: string | null, retries?: number | null, qrcode?: string | null, novoreg_date?: any | null, progrev_good?: boolean | null, chatsCount?: number | null, adbHost?: string | null, messagesCount?: number | null } | null } | null };


export const ConnectionSessionWASubscriptionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"ConnectionSessionWASubscription"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"host"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"connectionSessionWASubscribe"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"host"},"value":{"kind":"Variable","name":{"kind":"Name","value":"host"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConnectionSessionWA"}}]}}]}},...ConnectionSessionWA.definitions]} as unknown as DocumentNode;

/**
 * __useConnectionSessionWASubscription__
 *
 * To run a query within a React component, call `useConnectionSessionWASubscription` and pass it any options that fit your needs.
 * When your component renders, `useConnectionSessionWASubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionSessionWASubscription({
 *   variables: {
 *      host: // value for 'host'
 *   },
 * });
 */
export function useConnectionSessionWASubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<ConnectionSessionWASubscription, ConnectionSessionWASubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<ConnectionSessionWASubscription, ConnectionSessionWASubscriptionVariables>(ConnectionSessionWASubscriptionDocument, options);
      }
export type ConnectionSessionWASubscriptionHookResult = ReturnType<typeof useConnectionSessionWASubscription>;
export type ConnectionSessionWASubscriptionSubscriptionResult = Apollo.SubscriptionResult<ConnectionSessionWASubscription>;