import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BroadcastAccounUserWa } from '__generated__/types';

interface ClientsState {
  clients: BroadcastAccounUserWa[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: ClientsState = {
  clients: [],
  status: 'idle',
  error: null,
};

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setClients: (state, action: PayloadAction<BroadcastAccounUserWa[]>) => {
      state.clients = action.payload;
      state.status = 'succeeded';
    },
    setLoading: (state) => {
      state.status = 'loading';
    },
    setError: (state, action: PayloadAction<string>) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const { setClients, setLoading, setError } = clientsSlice.actions;
export default clientsSlice.reducer;
