import { SignIn, useUser } from '@clerk/clerk-react';
import { Spin } from 'antd';
import { AppRouter } from 'app/appRouter';
import Layout from 'app/layout';
import {
  ConnectionSessionWAList,
  useConnectionSessionWAList,
} from 'features/waAccounts/__generated__/ConnectionSessionWAListQuery';
import { useConnectionSessionWASubscription } from 'features/waAuth/__generated__/ConnectionSessionWASubscription';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAccounts, setError, setLoading } from 'store/slices/accountsSlice';
import { setAuthState } from 'store/slices/authSlice';
import { setUserData } from 'store/slices/userSlice';
import { AppDispatch } from 'store/store';

const App = () => {
  const { user, isLoaded } = useUser();
  const dispatch = useDispatch<AppDispatch>();
  const [showAnimation, setShowAnimation] = useState(true);

  const {
    data: accounts,
    loading,
    error,
  } = useConnectionSessionWAList({
    variables: { filter: {} },
    pollInterval: 5000,
  });

  useConnectionSessionWASubscription({
    onData: ({ data: subscriptionData }) => {
      if (
        subscriptionData?.data?.connectionSessionWASubscribe &&
        accounts?.connectionSessionWAList
      ) {
        const updatedAccounts = {
          connectionSessionWAList: accounts.connectionSessionWAList.map((elem) => {
            const match =
              elem?.waClient?.host ===
              subscriptionData?.data?.connectionSessionWASubscribe?.waClient?.host;
            if (match) {
              return subscriptionData?.data?.connectionSessionWASubscribe;
            }

            return elem;
          }),
        };

        dispatch(setAccounts(updatedAccounts as ConnectionSessionWAList));
      }
    },
  });

  useEffect(() => {
    const serializedUser = user
      ? {
          id: user.id,
          fullName: user.fullName,
          email: user.primaryEmailAddress?.emailAddress || null,
          imageUrl: user.imageUrl || null,
        }
      : undefined;

    dispatch(setAuthState({ user: serializedUser, isLoaded }));

    if (isLoaded && user) {
      const membership = user.organizationMemberships?.[0];
      if (membership) {
        dispatch(
          setUserData({
            userRole: membership.role || '',
            userOrg: membership.organization?.name || '',
            userName: user.fullName?.toString() || 'user',
          })
        );
      }
    }
  }, [user, isLoaded, dispatch]);

  useEffect(() => {
    dispatch(setLoading(loading));
    if (error) {
      dispatch(setError(error.message));
    } else {
      dispatch(setAccounts(accounts));
    }
  }, [accounts, loading, error, dispatch]);

  useEffect(() => {
    if (isLoaded) {
      const timer = setTimeout(() => {
        setShowAnimation(false);
      }, 1800);

      return () => clearTimeout(timer);
    }
  }, [isLoaded]);

  if (showAnimation || !isLoaded)
    return (
      <div className="flex h-screen items-center justify-center bg-surfaceDark">
        <div className="flex w-max flex-row items-center gap-4">
          <Spin />
          <h1 className="animate-typing cursor-progress select-none overflow-hidden whitespace-nowrap border-r-4 border-r-white pr-5 text-4xl font-bold text-white">
            Atlant Consult
          </h1>
        </div>
      </div>
    );

  if (!user) {
    return (
      <div className="flex h-screen items-center justify-center">
        <SignIn />
      </div>
    );
  }

  return (
    <Layout>
      <AppRouter />
    </Layout>
  );
};

export default App;
