import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { setLimitFilter } from 'store/slices/filterSlice';
import { RootState } from 'store/store';

const LimitFilters = () => {
  const dispatch = useDispatch();
  const { limitFilter } = useSelector((state: RootState) => state.filter);

  const limitList = [
    { value: 5000, label: '5000' },
    { value: 10000, label: '10000' },
    { value: 50000, label: '50000' },
    { value: 100000, label: '100000' },
  ];

  const handleLimitChange = (value: number) => {
    dispatch(setLimitFilter(limitFilter === value ? 5000 : value));
  };

  return (
    <motion.div
      key="version-filters"
      animate={{ opacity: [0, 1], gap: [0, '8px'] }}
      transition={{ duration: 0.3, delay: 0.2 }}
      className="flex flex-row items-center"
    >
      {limitList.map((limit) => (
        <button
          key={limit.label}
          onClick={() => handleLimitChange(limit.value)}
          className={`group flex select-none items-center justify-center rounded-lg duration-150 dark:text-white ${limitFilter === limit.value ? 'bg-green-600' : 'bg-surfaceDarker hover:bg-green-200 dark:bg-surfaceDark dark:hover:bg-green-600/40'} gap-2 px-3 py-1`}
        >
          <p
            className={`text-sm font-medium ${limitFilter === limit.value ? 'text-white' : 'dark:text-white'}`}
          >
            {limit.label}
          </p>
        </button>
      ))}
    </motion.div>
  );
};

export default LimitFilters;
