import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import SideMenu from 'shared/components/sideMenu';
import Header from 'widgets/header';

export default function Layout({ children }: { children: React.ReactNode }) {
  const { userRole, userOrg } = useSelector((state: RootState) => state.user);
  const isOpgt = userOrg === 'AC_OPGT';

  return (
    <>
      <Header />
      <main className="relative flex h-[calc(100vh-110px)] w-full gap-x-2 px-[6%] lg:px-[1%] xl:gap-x-4">
        {userRole && !isOpgt && (
          <div className="pageBlock min-w-[250px]">
            <SideMenu />
          </div>
        )}

        <div className="flex w-full flex-row gap-x-2 xl:gap-x-4">{children}</div>
      </main>
    </>
  );
}
