import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConnectionSessionWAList } from 'features/waAccounts/__generated__/ConnectionSessionWAListQuery';

interface AccountsState {
  accounts: ConnectionSessionWAList | undefined;
  versions: Record<string, number> | undefined;
  loading: boolean;
  error: string | null;
}

const initialState: AccountsState = {
  accounts: undefined,
  versions: undefined,
  loading: false,
  error: null,
};

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setAccounts: (state, action: PayloadAction<ConnectionSessionWAList | undefined>) => {
      state.accounts = action.payload;
      if (action.payload?.connectionSessionWAList) {
        state.versions = action.payload.connectionSessionWAList.reduce(
          (acc, current) => {
            const version = current?.versionCode ?? '';
            if (version) {
              acc[version] = (acc[version] || 0) + 1;
            }
            return acc;
          },
          {} as Record<string, number>
        );
      } else {
        state.versions = undefined;
      }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const { setAccounts, setLoading, setError } = accountsSlice.actions;
export default accountsSlice.reducer;
