import { ClientStatus } from '__generated__/types';
import { Badge, ConfigProvider } from 'antd';
import { ConnectionSessionWAList } from 'features/waAccounts/__generated__/ConnectionSessionWAListQuery';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setVpsFilter } from 'store/slices/filterSlice';
import { RootState } from 'store/store';
import { themeIsDark } from 'utils/appTheme';

interface Props {
  accs: ConnectionSessionWAList | undefined;
}

const VpsFilters = ({ accs }: Props) => {
  const dispatch = useDispatch();
  const { vpsFilter, authFilter } = useSelector((state: RootState) => state.filter);

  const filters = {
    WA_01: 'AC_WA_01',
    WA_02: 'AC_WA_02',
    WA_03: 'AC_WA_03',
    WA_04: 'AC_WA_04',
    WA_05: 'AC_WA_05',
    WA_06: 'AC_WA_06',
    WA_07: 'AC_WA_07',
    WA_08: 'AC_WA_08',
    WA_09: 'AC_WA_09',
    WA_10: 'AC_WA_10',
    WA_11: 'AC_WA_11',
    WA_12: 'AC_WA_12',
    WA_13: 'AC_WA_13',
    WA_14: 'AC_WA_14',
  };

  const [, setFilteredAccounts] = useState<(ClientStatus | null)[] | null | undefined>();

  useEffect(() => {
    const filteredAccounts = accs?.connectionSessionWAList
      ?.filter((acc) => {
        const status = acc?.waClient?.status?.toString()?.toLowerCase() || '';
        const filter = authFilter?.toLowerCase() || '';
        return status.includes(filter);
      })
      .map((acc) => ({ ...acc, _id: acc?.waClient?._id || '' }));
    setFilteredAccounts(filteredAccounts || null);
  }, [accs, authFilter]);

  const handleVpsFilterChange = (value: string) => {
    dispatch(setVpsFilter(vpsFilter === value ? '' : value));
  };

  return (
    <motion.div
      key="vps-filters"
      animate={{ opacity: [0, 1], gap: [0, '8px'] }}
      transition={{ duration: 0.3, delay: 0.2 }}
      className="flex flex-row items-center"
    >
      {Object.entries(filters).map(([key, value], index) => (
        <ConfigProvider
          key={index}
          theme={{
            token: {
              colorBorderBg: 'transparent',
              fontSize: 13,
              colorBgBase: themeIsDark ? 'white' : 'black',
            },
            components: {
              Badge: {
                indicatorZIndex: 20,
              },
            },
          }}
        >
          <Badge
            color={themeIsDark ? '#505b65' : '#eaf5ff'}
            count={
              accs?.connectionSessionWAList?.filter((acc) => {
                const status = acc?.waClient?.status?.toString()?.toLowerCase() || '';
                const filter = authFilter?.toLowerCase() || '';
                const vps = acc?.nameVitrualMachine?.toLowerCase() || '';
                return status.includes(filter) && vps.includes(value.toLowerCase());
              }).length
            }
            overflowCount={999}
          >
            <button
              key={key}
              onClick={() => handleVpsFilterChange(value)}
              className={`group flex select-none items-center justify-center rounded-lg duration-150 dark:text-white ${vpsFilter === value ? 'bg-green-600' : 'bg-surfaceDarker hover:bg-green-200 dark:bg-surfaceDark dark:hover:bg-green-600/40'} gap-2 px-3 py-1`}
            >
              <p
                className={`text-sm font-medium ${vpsFilter === value ? 'text-white' : 'dark:text-white'}`}
              >
                {key}
              </p>
            </button>
          </Badge>
        </ConfigProvider>
      ))}
    </motion.div>
  );
};

export default VpsFilters;
